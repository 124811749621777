import { posthog } from 'posthog-js';
import { useState } from 'react';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import { BodyText } from '../../../common/components/texts';
import { appConfig } from '../../../config';
import Routes from '../../app/Routes';
import { User } from '../../user/forces/types';
import styles from './BookingSuccess.module.css';

type Props = {
  user: User | undefined;
};

const BookingSuccessMemberGuestSection = ({ user }: Props) => {
  const isMember = user != null;
  const referralCode = user?.referralCode.toString();
  const refferalLink = `https://app.olio.no${Routes.Register()}/?referralCode=${referralCode}`;
  const [showLinkText, setShowLinkText] = useState(false);

  const textForMembers =
    'Verv en venn og dere vil begge få 200,- å bruke til booking av behandlinger. Trykk på knappen for å kopiere din vervekode og del den med vennene dine';
  const textForGuests =
    'Vil du bli medlem og få hver 10.behandling gratis? Du tjener 10% olio-poeng per behandling, hvor ett poeng tilsvarer én krone. Bli medlem idag, helt gratis!';

  const copyToClipboard = () => {
    if (!user) return;
    navigator.clipboard.writeText(refferalLink);
  };

  const onClickMemberButton = () => {
    setShowLinkText(true);
    copyToClipboard();
    posthog.capture('Referral link clicked', { referralCode });
    setTimeout(() => {
      setShowLinkText(false);
    }, 2500);
  };

  const onClickGuestButton = () => {
    posthog.capture('Go to register clicked');
    window.location.href = Routes.Register();
  };

  return (
    <div className={styles.guestMemberTextWrapper}>
      <BodyText className={styles.noMarginBottom}>
        {isMember ? textForMembers : textForGuests}
      </BodyText>
      <div className={styles.buttonGroupWrapper}>
        {/* <CallToActionButton
          styleType="outlinedSecondary"
          theme="dark"
          onClick={() => {
            window.location.href = appConfig.websiteDomain;
          }}
          className={styles.buttonWrapper}
        >
          Gå til olio.no
        </CallToActionButton> */}
        {isMember ? (
          <div className={styles.memberButtonWrapper}>
            <CallToActionButton
              styleType="primary"
              onClick={onClickMemberButton}
              className={styles.buttonWrapper}
            >
              Verv venner
            </CallToActionButton>
            {showLinkText && <p className={styles.linkCopiedText}>Link kopiert</p>}
          </div>
        ) : (
          <CallToActionButton
            styleType="primary"
            onClick={onClickGuestButton}
            className={styles.buttonWrapper}
          >
            Bli medlem
          </CallToActionButton>
        )}
      </div>
    </div>
  );
};

export default BookingSuccessMemberGuestSection;
