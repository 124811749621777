import { Service } from '../../../../../root.types';

export const roundNumber = (value: number, precision: number = 2) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

// TODO: Everything regarding price should be calculated and returned from the backend...

export const calculateTotalPrice = (objects: Array<{ price: number; discountPrice?: number }>) => {
  return objects.reduce((previousValue, value) => {
    if (typeof value.discountPrice === 'number' && value.discountPrice < value.price) {
      return previousValue + value.discountPrice;
    }
    return previousValue + value.price;
  }, 0);
};

export const calculateTotalPriceWithDynamicHours = (
  objects: Array<{ price: number; discountPrice?: number }>,
  appliedDiscountPercentage: number
) => {
  return objects.reduce((previousValue, value) => {
    if (typeof value.discountPrice === 'number' && value.discountPrice < value.price) {
      return previousValue + value.discountPrice;
    }

    if (appliedDiscountPercentage > 0) {
      return previousValue + value.price * (1 - appliedDiscountPercentage);
    }

    return previousValue + value.price;
  }, 0);
};

export const calculateTotalDuration = (objects: Array<{ duration: number }>) => {
  return objects.reduce((previousValue, value) => {
    return previousValue + value.duration;
  }, 0);
};

export const removeDeselectedService = (
  selectedServices: Service[],
  deselectedService: Service
) => {
  const servicesWithoutDeselected = selectedServices.filter((service) => {
    return service.id !== deselectedService.id;
  });
  if (deselectedService.additionalServices.length === 0) return servicesWithoutDeselected;
  const servicesWithoutChildServices = servicesWithoutDeselected.filter((service) => {
    if (!service.isChild) return true;
    return service.parentServiceId !== deselectedService.id;
  });
  return servicesWithoutChildServices;
};

export const getSortedServicesByPrice = (services: Service[]) =>
  services.sort((a, b) => a.price - b.price);
