import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import LoadingIndicator from '../../../common/components/loadingIndicator/LoadingIndicator';
import { BodyText, H3 } from '../../../common/components/texts';
import { setSelectedClinic } from '../forces/actions';
import { getSelectedClinic } from '../forces/selectors';
import {
  fetchAvailableDateSuccess,
  getAvailabilityForClinics,
} from '../sections/calendarSection/forces/actions';
import {
  getIsLoadingOtherBookableClinics,
  getOtherBookableClinics,
  getShouldFetchOtherBookableClinics,
} from '../sections/clinicSection/forces/selectors';
import { IOtherBookableClinics } from '../sections/clinicSection/forces/types';
import styles from './OtherBookableClinicsSuggestion.module.css';
import { appConfig } from '../../../config';

const SOME_CLINICS_AVAILABLE = `Vi har ikke ledige timer på valgt lokasjon, men vi har ledige timer på følgende lokasjoner`;

const OtherBookableClinicsSuggestion = () => {
  const shouldFetchOtherBookableClinics = useSelector(getShouldFetchOtherBookableClinics);
  const otherBookableClinics = useSelector(getOtherBookableClinics);
  const isLoading = useSelector(getIsLoadingOtherBookableClinics);
  const selectedClinic = useSelector(getSelectedClinic);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldFetchOtherBookableClinics) {
      dispatch(getAvailabilityForClinics());
    }
  }, [shouldFetchOtherBookableClinics]);

  const handleClinicSelection = useCallback(
    (otherBookableClinic: IOtherBookableClinics) => {
      dispatch(setSelectedClinic(otherBookableClinic.clinic));
      dispatch(fetchAvailableDateSuccess(otherBookableClinic.availability));
    },
    [dispatch]
  );

  const noBookableDateInAllClinics =
    !isLoading && shouldFetchOtherBookableClinics && !otherBookableClinics;

  if (noBookableDateInAllClinics) {
    return (
      <div className={styles.otherBookableClinicsContainer}>
        <H3 className={styles.text}>
          Vi har ingen ledige timer for disse behandlingene. Prøv å del opp bookingen eller ta
          kontakt med oss{' '}
          <a style={{ color: '#8e4f59' }} href={`${appConfig.typeform.url}`}>
            her.
          </a>
        </H3>
      </div>
    );
  }

  if (!shouldFetchOtherBookableClinics) return null;

  return (
    <div className={styles.otherBookableClinicsContainer}>
      {isLoading ? (
        <LoadingIndicator loadingText="Fant ingen ledige tider. Ser etter ledige behandlere på andre klinikker." />
      ) : (
        <BodyText className={styles.text}>{SOME_CLINICS_AVAILABLE}</BodyText>
      )}
      <div className={styles.clinicButtonsContainer}>
        {otherBookableClinics &&
          otherBookableClinics.map((otherBookableClinic: IOtherBookableClinics) => (
            <CallToActionButton
              key={otherBookableClinic.clinic.id}
              styleType={
                selectedClinic && selectedClinic.id === otherBookableClinic.clinic.id
                  ? 'tertiary'
                  : 'outlinedQuaternary'
              }
              theme="light"
              size="normal"
              onClick={() => handleClinicSelection(otherBookableClinic)}
            >
              {otherBookableClinic.clinic.name}
            </CallToActionButton>
          ))}
      </div>
    </div>
  );
};

export default OtherBookableClinicsSuggestion;
