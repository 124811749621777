import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { connect } from 'react-redux';
import FormInput from '../../../common/components/form/FormInput';
import { BodyText, H1, H2 } from '../../../common/components/texts';
import { getCurrentHostname } from '../../../common/utils/url.utils';
import { appConfig } from '../../../config';
import { RootState } from '../../../root.reducer';
import Routes from '../../app/Routes';
import { getCurrentUser } from '../forces/selectors';
import styles from './ReferFriend.module.css';

const getReferralRegisterURL = (referralCode: string) => {
  return `${getCurrentHostname()}${Routes.Register()}/?referralCode=${referralCode}`;
};

interface OwnProps {}
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps & React.HTMLAttributes<HTMLElement> & RouteComponentProps;

const ReferFriend: React.FunctionComponent<Props> = ({
  className,
  children,
  currentUser,
  ...props
}) => {
  if (!currentUser) return null;
  return (
    <div className={styles.container}>
      <H2 className={styles.text}>
        Få {appConfig.referralCreditAward} olio-poeng for hver venn du verver!
      </H2>
      <BodyText className={styles.text}>
        For hver venn du verver, får begge {appConfig.referralCreditAward} olio-poeng hver. Poengene
        dukker opp i profilen din når vennen din har booket sin første behandling.
        <br />
        Her er vervekoden din - spread the love!
      </BodyText>

      <H1 className={styles.text}>{currentUser.referralCode}</H1>

      <FormInput value={getReferralRegisterURL(currentUser.referralCode)} onChange={() => {}} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: getCurrentUser(state),
});

export default connect<StateProps, {}, OwnProps, RootState>(mapStateToProps, {})(ReferFriend);
