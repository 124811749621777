import { useDispatch } from 'react-redux';
import Button from '../../../common/components/button/Button';
import { setActiveTabMenuContent } from '../forces/actions';
import { TabMenuContent } from '../UserPage';
import styles from './Overview.module.css';

const Overview = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <Button
        title="Vern en venn"
        onClick={() => dispatch(setActiveTabMenuContent(TabMenuContent.Referfriend))}
      />
      <Button
        title="Personlig info"
        onClick={() => dispatch(setActiveTabMenuContent(TabMenuContent.UserInfo))}
      />
      <Button
        title="Endre passord"
        onClick={() => dispatch(setActiveTabMenuContent(TabMenuContent.ChangePassword))}
      />
    </div>
  );
};

export default Overview;
